:root {
    /* --darkness: #15171b; */
    --darkness: #000000;
    --hackerGreen: #8bbe37;
    --coolGray: #131313;
    --coolBlue: #1c86ec;
    --coolYellow: #fec33b;
    --text: #b1afa7;
    --textGray: #50575f;
    --lightBackground: #d6dfdc;
}
