.widget {
    border-radius: 2px;
    height: 100%;
    overflow: hidden;
    position: relative;
}

#App.light .widget {
    background-color: white;
    /* box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2); */
}
#App.dark .widget {
    background-color: var(--coolGray);
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.03);
}

.widget .header {
    height: 20px;
    cursor: move;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
}

#App.dark .widget .header {
    background-color: var(--coolGray);
    color: white;
}
#App.light .widget .header {
    color: black;
}

.widget .header .widgetTitle {
    font-weight: 900;
    display: flex;
    align-items: center;
}
.widget .header .widgetButtons {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
}

.widget .content {
    height: calc(100% - 20px);
    overflow: hidden;
}

#App.dark .NoteWidget {
    color: white;
}
.NoteWidget .content {
    padding: 10px;
}
.NoteWidget .note_title {
    font-weight: 900;
    color: var(--darkness);
    padding-bottom: 10px;
    border: none;
    outline: none;
    display: block;
    width: 100%;
    background: none;
}
.NoteWidget .note_content {
    width: 100%;
    height: 90%;
    border: none;
    outline: none;
    background: none;
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    font-size: 12px;
    resize: none;
}

.BTCHalvingWidget .content {
    padding: 0 10px;
    color: var(--darkness);
    font-size: 12px;
    font-weight: 600;
}

#App.dark .BTCHalvingWidget .content {
    color: var(--coolYellow);
}

#App.dark .NoteWidget .note_title {
    color: white;
}

#App.dark .NoteWidget .note_content {
    color: white;
}

.PodcastsWidget .content {
    padding: 10px;
    overflow-y: auto;
}

.BookmarksWidget .content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.BookmarksWidget .content ul {
    margin: 0;
    padding: 0;
}
.BookmarksWidget .content ul li {
    list-style-type: none;
    margin-bottom: 10px;
}
.BookmarksWidget .content ul li a {
    text-decoration: none;
    font-size: 12px;
    padding: 10px;
    border: 1px solid #f3f3f3;
    color: var(--darkness);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
}

.BookmarksWidget .content ul li a:hover {
    background: var(--coolYellow);
}

#App.dark .BookmarksWidget .content ul li a {
    color: var(--coolYellow);
    border-color: var(--coolYellow);
}

#App.dark .BookmarksWidget .content ul li a:hover {
    color: var(--coolYellow);
    color: var(--darkness);
}

.BookmarksWidget .content button {
    outline: none;
    border: none;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 10px;
    border-radius: 6px;
    background: var(--coolYellow);
    cursor: pointer;
}

.BookmarksWidget .createBookmarkForm {
    padding: 10px;
    border: 1px solid var(--coolYellow);
    border-radius: 6px;
    margin-bottom: 20px;
}

.BookmarksWidget .createBookmarkForm label {
    font-size: 12px;
}

.BookmarksWidget .createBookmarkForm input {
    border: none;
    outline: none;
    padding: 10px;
    background: var(--lightBackground);
    width: 100%;
    border-radius: 6px;
    color: var(--darkness);
}
